@use 'styles/variables' as v;
@use 'styles/colors' as c;

body {
  margin: 0;
  font-family: v.$font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *::before, *::after {
  box-sizing: border-box;
}
