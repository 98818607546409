
$gap: 20px;

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: $gap;
  margin-top: 36px;
}

.image_container {
  border: 1px solid #000000;
}

.column {
  .image_container {
    margin-bottom: $gap;
  }
}

.image {
  border: 2px solid #ffffff;
  display: block;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.wide {
  width: 415px;
  grid-column-end: span 2;
}
.tall {
  height: 415px;
  grid-row-end: span 2;
}
