@use '../../styles/colors' as c;

.ImageViewer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ImageViewer.ImageViewer--Fullscreen {
  position: fixed;
  background: c.$black-100;
  color: c.$white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-content: space-between;
}

.ImageViewer--Fullscreen .ImageViewer__Button svg {
  fill: var(--primary-color);
  opacity: 0.6;
}

.ImageViewer--Fullscreen .ImageViewer__Button:hover svg {
  fill: var(--primary-color);
  opacity: 1;
}

.ImageViewer--Fullscreen .ImageViewer__Button {
  align-self: initial;
}

.ImageViewer__CloseFullscreen {
  position: fixed;
  top: 0;
  right: 0;
}

.ImageViewer__Button {
  display: block;
  background: none;
  color: inherit;
  padding: 10px;
  border: none;
  cursor: pointer;
  align-self: stretch;

  svg {
    fill: var(--primary-color);
  }

  &:hover svg {
    fill: var(--secondary-color);
  }
}

.ImageViewer--Fullscreen .ImageViewer__Main {
  flex-direction: column;
}

.ImageViewer__Main {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
}

.ImageViewer--Fullscreen .ImageViewer__Title {
  color: c.$white;
  margin-bottom: -10px;
  top: initial;
  bottom: 0;
  left: 50%;
  transform: translateY(10px) translateX(-50%);
  opacity: 1;
}

.ImageViewer__Title {
  text-align: center;
  color: var(--secondary-color);
  transition-property: transform, opacity;
  transition-duration: 0.22s;
  transition-timing-function: linear;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(0px) translateX(-50%);
  opacity: 0;
  z-index: 1;
}

.ImageViewer--Fullscreen .ImageViewer__ImageContainer:hover + .ImageViewer__Title {
  transform: translateY(10px) translateX(-50%);
}

.ImageViewer__ImageContainer:hover + .ImageViewer__Title {
  opacity: 1;
  transform: translateY(-22px) translateX(-50%);
}

.ImageViewer--Fullscreen .ImageViewer__ImageContainer img {
  max-width: 80%;
  max-height: 100vh;
  //height: 80%;
  margin: 0 auto;
}

.ImageViewer__ImageContainer {
  background: c.$black-100;
  border: 1px solid c.$black-100;
  display: inline-block;
  z-index: 100;
  margin: 0 auto;

  img {
    display: block;
    border: 2px solid c.$white;
    cursor: pointer;

    max-width: 100%;
    max-height: 328px;
  }
}
