.ItemList {
  section {
    display: grid;
    grid-template-columns: 5rem 1fr;
    grid-gap: 10px;
    margin-bottom: 6px;
  }
}

.ItemList__Label {
  text-transform: lowercase;
  color: var(--secondary-color);
  text-align: right;
}
