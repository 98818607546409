@use '../../styles/variables' as v;
@use '../../styles/colors' as c;

.Header {
  text-transform: lowercase;
  font-size: inherit;
  font-weight: inherit;
  color: c.$black-100;
}

.Header__Title {
  display: inline-block;
  color: var(--secondary-color);

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      //background: c.$black-100;
      //color: c.$white;
      text-decoration: underline;
    }
  }

  &:after {
    content: "//";
    margin: 0 10px;
    color: c.$black-75;
  }
}

.Header__Page {
  display: inline-block;
  color: var(--secondary-color);
}
