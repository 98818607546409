.button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  fill: var(--primary-color);
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  cursor: pointer;

  &:hover {
    fill: var(--secondary-color);
  }
}
