.Home {
  padding-top: 100px;
}

.Home__Header {
  text-align: center;
  margin-top: 30px;
}

.Home__Title {
  color: var(--secondary-color);
  font-size: inherit;
  font-weight: inherit;
  text-transform: lowercase;
}

.Home__Navigation {
  margin-top: 20px;

  ul {
    padding: 0;
    display: flex;
    list-style-type: none;
    margin: 0 auto 15px;
    justify-content: center;

    &:last-child {
      margin-bottom: 0;
    }

    &:last-of-type li:not(:last-child):after {
      content: "–";
    }

    li {
      &:not(:last-child):after {
        content: "//";
        margin: 0 8px;
      }

      a {
        color: var(--primary-color);
        text-decoration: none;
        padding: 4px 4px;
        display: inline-block;

        &:hover {
          color: var(--secondary-color);
          text-decoration: underline;
        }
      }
    }
  }
}
