@use '../../styles/variables' as v;
@use '../../styles/colors' as c;

.App {
  background: var(--background);
  min-height: 100vh;
  font-size: v.$font-size;
  color: var(--primary-color);
  font-weight: 400;
  padding: 100px 8px 0;
}

.App ::selection {
  background: var(--selection-background);
  color: var(--selection-color);
}

:root {
  --background: #{c.$white};
  --primary-color: #{c.$black-65};
  --secondary-color: #{c.$black-100};

  --selection-background: #{c.$black-100};
  --selection-color: #{c.$white};
}

.theme-dark {
  --background: #{c.$black-100};
  --primary-color: #{c.$black-75};
  --secondary-color: #{c.$white};

  --selection-background: #{c.$white};
  --selection-color: #{c.$black-100};
}
